import Page from '@/components/Page'

import AccountsMetricsChart from './AccountsMetricsChart'
import ExchangeMetricsChart from './ExchangeMetricsChart'
import FlashMetricsChart from './FlashMetricsChart'
import StarterMetricsChart from './StarterMetricsChart'
import StashMetricsChart from './StashMetricsChart'
import { METRICS_TABS } from '@/constants/tabs'

export const MetricsCharts = () => {
  return (
    <Page permissionSlug='metrics_view' pageTitle='Metrics' tabs={METRICS_TABS}>
      <AccountsMetricsChart />
      <div className='h-0.5 w-full bg-foreground/50' />
      <ExchangeMetricsChart />
      <div className='h-0.5 w-full bg-foreground/50' />
      <FlashMetricsChart />
      <div className='h-0.5 w-full bg-foreground/50' />
      <StashMetricsChart />
      <div className='h-0.5 w-full bg-foreground/50' />
      <StarterMetricsChart />
    </Page>
  )
}
