import { useQuery } from '@tanstack/react-query'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LoaderIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import Menu from '@/components/Menu'

import { IAccountsMetricsData } from '@/types/metrics-charts'

import { getChartOptions } from './get-chart-options'
import api from '@/api'

ChartJS.register(
  TimeScale,
  LinearScale,
  CategoryScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
)

const AccountsMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')
  const options = useMemo(() => getChartOptions(range), [range])

  const { data, isLoading, isError } = useQuery({
    queryKey: ['accountsMetrics', range],
    queryFn: async () =>
      await api.get<IAccountsMetricsData>(
        `/admin/metrics/charts/accounts?range=${range}`
      ),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const activeLastHour = rawData.map(d => d.active_last_hour)
      const activeLastDay = rawData.map(d => d.active_last_day)
      const activeLastWeek = rawData.map(d => d.active_last_week)
      const activeLastMonth = rawData.map(d => d.active_last_month)
      const total = rawData.map(d => d.total)
      const totalReferred = rawData.map(d => d.total_referred)
      const totalDownloads = rawData.map(d => d.total_downloads)
      const totalWaitlisted = rawData.map(d => d.total_waitlisted)
      const totalSatoshiPlus = rawData.map(d => d.total_satoshi_plus)

      const chartData: ChartData<'line'> = {
        labels,
        datasets: [
          {
            label: 'Active Last Hour',
            data: activeLastHour,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Day',
            data: activeLastDay,
            fill: false,
            borderColor: 'rgba(255,99,132,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Week',
            data: activeLastWeek,
            fill: false,
            borderColor: 'rgba(255,159,64,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Month',
            data: activeLastMonth,
            fill: false,
            borderColor: 'rgba(255,205,86,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total',
            data: total,
            fill: false,
            borderColor: 'rgba(54,162,235,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Referred',
            data: totalReferred,
            fill: false,
            borderColor: 'rgba(153,102,255,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Downloads',
            data: totalDownloads,
            fill: false,
            borderColor: 'rgba(255,159,64,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Waitlisted',
            data: totalWaitlisted,
            fill: false,
            borderColor: 'rgba(75,192,192,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5
          },
          {
            label: 'Total Satoshi Plus',
            data: totalSatoshiPlus,
            fill: false,
            borderColor: 'rgba(255,99,132,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          }
        ]
      }

      return chartData
    }
  })

  return (
    <section className='flex w-full flex-col gap-2'>
      <div className='flex flex-wrap items-center justify-between gap-1'>
        <h2 className='text-xl font-bold lg:text-3xl'>Accounts Metrics</h2>
        <Menu
          tabsArray={['day', 'week', 'month', 'all']}
          setSelectedTab={(selectedRange: string) => {
            setRange(selectedRange as 'day' | 'week' | 'month' | 'all')
          }}
          selectedTab={range}
        />
      </div>
      {isLoading ? (
        <LoaderIcon className='mx-auto size-8 animate-spin text-orange550' />
      ) : isError || !data ? (
        <div>Failed to load chart data.</div>
      ) : (
        <Line data={data} options={options} />
      )}
    </section>
  )
}

export default AccountsMetricsChart
