import { ChartOptions } from 'chart.js'

export const getChartOptions = (
  range: 'day' | 'week' | 'month' | 'all'
): ChartOptions<'line'> => {
  let unit: 'hour' | 'day' | 'month' = 'day'

  switch (range) {
    case 'day':
      unit = 'hour'
      break
    case 'week':
    case 'month':
      unit = 'day'
      break
    case 'all':
      unit = 'month'
      break
  }

  return {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: unit
        }
      },
      y: {
        beginAtZero: false
      }
    },
    plugins: {
      legend: {
        onHover: event => {
          ;(event.native?.target as HTMLCanvasElement).style.cursor = 'pointer'
        },
        onLeave: event => {
          ;(event.native?.target as HTMLCanvasElement).style.cursor = 'default'
        }
      }
    }
  }
}
