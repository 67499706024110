import { useQuery } from '@tanstack/react-query'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LoaderIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import Menu from '@/components/Menu'

import { IExchangeMetricsData } from '@/types/metrics-charts'

import { getChartOptions } from './get-chart-options'
import api from '@/api'

ChartJS.register(
  TimeScale,
  LinearScale,
  CategoryScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
)

const ExchangeMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')
  const options = useMemo(() => getChartOptions(range), [range])

  const { data, isLoading, isError } = useQuery({
    queryKey: ['exchangeMetrics', range],
    queryFn: async () =>
      await api.get<IExchangeMetricsData>(
        `/admin/metrics/charts/exchange?range=${range}`
      ),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const custodiedBtc = rawData.map(d => d.custodied_btc)
      const custodiedUsd = rawData.map(d => d.custodied_usd)
      const withdrawalMonthUsd = rawData.map(d => d.withdrawal_month_usd)
      const withdrawalWeekUsd = rawData.map(d => d.withdrawal_week_usd)
      const withdrawalDayUsd = rawData.map(d => d.withdrawal_day_usd)
      const depositMonthUsd = rawData.map(d => d.deposit_month_usd)
      const depositWeekUsd = rawData.map(d => d.deposit_week_usd)
      const depositDayUsd = rawData.map(d => d.deposit_day_usd)
      const tradesBuyMonthUsd = rawData.map(d => d.trades_buy_month_usd)
      const tradesBuyWeekUsd = rawData.map(d => d.trades_buy_week_usd)
      const tradesBuyDayUsd = rawData.map(d => d.trades_buy_day_usd)
      const tradesSellMonthUsd = rawData.map(d => d.trades_sell_month_usd)
      const tradesSellWeekUsd = rawData.map(d => d.trades_sell_week_usd)
      const tradesSellDayUsd = rawData.map(d => d.trades_sell_day_usd)

      const chartData: ChartData<'line'> = {
        labels,
        datasets: [
          {
            label: 'Custodied BTC',
            data: custodiedBtc,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5
          },
          {
            label: 'Custodied USD',
            data: custodiedUsd,
            fill: false,
            borderColor: 'rgba(255,99,132,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Withdrawal Month (USD)',
            data: withdrawalMonthUsd,
            fill: false,
            borderColor: 'rgba(255,159,64,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Withdrawal Week (USD)',
            data: withdrawalWeekUsd,
            fill: false,
            borderColor: 'rgba(255,205,86,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Withdrawal Day (USD)',
            data: withdrawalDayUsd,
            fill: false,
            borderColor: 'rgba(54,162,235,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Deposit Month (USD)',
            data: depositMonthUsd,
            fill: false,
            borderColor: 'rgba(153,102,255,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Deposit Week (USD)',
            data: depositWeekUsd,
            fill: false,
            borderColor: 'rgba(255,159,64,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Deposit Day (USD)',
            data: depositDayUsd,
            fill: false,
            borderColor: 'rgba(75,192,192,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Buy Month (USD)',
            data: tradesBuyMonthUsd,
            fill: false,
            borderColor: 'rgba(255,99,132,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Buy Week (USD)',
            data: tradesBuyWeekUsd,
            fill: false,
            borderColor: 'rgba(54,162,235,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Buy Day (USD)',
            data: tradesBuyDayUsd,
            fill: false,
            borderColor: 'rgba(153,102,255,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Sell Month (USD)',
            data: tradesSellMonthUsd,
            fill: false,
            borderColor: 'rgba(255,205,86,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Sell Week (USD)',
            data: tradesSellWeekUsd,
            fill: false,
            borderColor: 'rgba(255,159,64,0.8)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Trades Sell Day (USD)',
            data: tradesSellDayUsd,
            fill: false,
            borderColor: 'rgba(75,192,192,0.8)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          }
        ]
      }

      return chartData
    }
  })

  return (
    <section className='flex w-full flex-col gap-2'>
      <div className='flex flex-wrap items-center justify-between gap-1'>
        <h2 className='text-xl font-bold lg:text-3xl'>Exchange Metrics</h2>
        <Menu
          tabsArray={['day', 'week', 'month', 'all']}
          setSelectedTab={(selectedRange: string) => {
            setRange(selectedRange as 'day' | 'week' | 'month' | 'all')
          }}
          selectedTab={range}
        />
      </div>
      {isLoading ? (
        <LoaderIcon className='mx-auto size-8 animate-spin text-orange550' />
      ) : isError || !data ? (
        <div>Failed to load chart data.</div>
      ) : (
        <Line data={data} options={options} />
      )}
    </section>
  )
}

export default ExchangeMetricsChart
