import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import toast from 'react-hot-toast'

import { Section } from '@/components/InfoSection'
import { SectionItem } from '@/components/InfoSection/SectionItem'
import { SubDescriptionSectionItem } from '@/components/InfoSection/SectionItem/SubDescriptionSectionItem'
import { TabsSectionItem } from '@/components/InfoSection/SectionItem/TabsSectionItem'
import Page from '@/components/Page'

import {
  ExchangeDataInterface,
  FlashDataInterface,
  IUserMetrics,
  StarterDataInterface,
  StashDataInterface
} from '@/types/metrics/metrics.interface'

import api from '@/api'
import { METRICS_TABS } from '@/constants/tabs'
import { formatMetricsData } from '@/routes/Metrics/Cards/metricsFormater'
import styles from '@/routes/Metrics/metrics.module.css'
import { cn } from '@/utils'

export function Metrics() {
  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await api.get<IUserMetrics>('/admin/metrics/users'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: exchangeData,
    isLoading: exchangeDataLoading,
    isError: exchangeDataError
  } = useQuery({
    queryKey: ['exchange'],
    queryFn: async () =>
      await api.get<ExchangeDataInterface>('/admin/metrics/exchange'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: flashData,
    isLoading: flashDataLoading,
    isError: flashDataError
  } = useQuery({
    queryKey: ['flash'],
    queryFn: async () =>
      await api.get<FlashDataInterface>('/admin/metrics/flash'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: stashData,
    isLoading: stashDataLoading,
    isError: stashDataError
  } = useQuery({
    queryKey: ['stash'],
    queryFn: async () =>
      await api.get<StashDataInterface>('/admin/metrics/stash'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: starterData,
    isLoading: starterDataLoading,
    isError: starterDataError
  } = useQuery({
    queryKey: ['starter'],
    queryFn: async () =>
      await api.get<StarterDataInterface>('/admin/metrics/starter'),
    enabled: true,
    select: res => res.data
  })

  const dataErrors = useMemo(() => {
    const errors = {
      users: usersError ? 'Could not get Users metrics' : '',
      exchange: exchangeDataError ? 'Could not get Exchange metrics' : '',
      flash: flashDataError ? 'Could not get Flash metrics' : '',
      starter: starterDataError ? 'Could not get Starter metrics' : '',
      stash: stashDataError ? 'Could not get Stash metrics' : ''
    }

    Object.entries(errors).map(([key, message]) => {
      if (!message) {
        return null
      }

      toast.error(message, {
        id: key
      })
      return null
    })

    return errors
  }, [
    usersError,
    exchangeData,
    exchangeDataError,
    flashData,
    flashDataError,
    starterData,
    starterDataError,
    stashData,
    stashDataError
  ])

  const metricsData = useMemo(() => {
    return formatMetricsData({
      userData: users,
      flash: flashData,
      exchange: exchangeData,
      starter: starterData,
      stash: stashData
    })
  }, [users, flashData, exchangeData, starterData, stashData])

  return (
    <Page permissionSlug='metrics_view' pageTitle='Metrics' tabs={METRICS_TABS}>
      <Section title='User Data'>
        {Object.entries(metricsData.userData).map(([key, item]) => (
          <SectionItem
            className={cn(
              'w-full min-w-0 flex-none',
              styles.sectionItemColumnFour
            )}
            key={key}
            label={item.label}
            description={item.description}
            loading={usersLoading}
            error={dataErrors.users}
          />
        ))}
      </Section>
      <Section title='Exchange' subtitle='ZeroHash'>
        <TabsSectionItem
          labelClassName='!text-orange550'
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnFour
          )}
          tabs={metricsData.exchange.bitcoinUnderCustody.tabs}
          description={metricsData.exchange.bitcoinUnderCustody.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
        <TabsSectionItem
          labelClassName='!text-blue500'
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnFour
          )}
          tabs={metricsData.exchange.dollarsUnderCustody.tabs}
          description={metricsData.exchange.dollarsUnderCustody.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
        <TabsSectionItem
          labelClassName='!text-lime500'
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnFour
          )}
          tabs={metricsData.exchange.buyVolume.tabs}
          description={metricsData.exchange.buyVolume.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
        <TabsSectionItem
          labelClassName='!text-orange500'
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnFour
          )}
          tabs={metricsData.exchange.sellVolume.tabs}
          description={metricsData.exchange.sellVolume.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
        <TabsSectionItem
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnTwo
          )}
          tabs={metricsData.exchange.depositVolume.tabs}
          description={metricsData.exchange.depositVolume.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
        <TabsSectionItem
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnTwo
          )}
          tabs={metricsData.exchange.withdrawalVolume.tabs}
          description={metricsData.exchange.withdrawalVolume.description}
          loading={exchangeDataLoading}
          error={dataErrors.exchange}
        />
      </Section>
      <Section title='Flash' subtitle='Breez'>
        <SubDescriptionSectionItem
          labelClassName='!text-purple400'
          label={metricsData.flash.totalBalance.label}
          description={metricsData.flash.totalBalance.description}
          subDescription={metricsData.flash.totalBalance.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={flashDataLoading}
          error={dataErrors.flash}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-lime500'
          label={metricsData.flash.volumeReceived.label}
          description={metricsData.flash.volumeReceived.description}
          subDescription={metricsData.flash.volumeReceived.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={flashDataLoading}
          error={dataErrors.flash}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-orange500'
          label={metricsData.flash.volumeSent.label}
          description={metricsData.flash.volumeSent.description}
          subDescription={metricsData.flash.volumeSent.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={flashDataLoading}
          error={dataErrors.flash}
        />
        <TabsSectionItem
          className={cn('w-full min-w-0 flex-none', styles.sect)}
          tabs={metricsData.flash.paymentsMade.tabs}
          description={metricsData.flash.paymentsMade.description}
          loading={flashDataLoading}
          error={dataErrors.flash}
        />
      </Section>
      <Section title='Stash' subtitle='BDK'>
        <SubDescriptionSectionItem
          labelClassName='!text-purple400'
          label={metricsData.stash.totalBalance.label}
          description={metricsData.stash.totalBalance.description}
          subDescription={metricsData.stash.totalBalance.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={stashDataLoading}
          error={dataErrors.stash}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-lime500'
          label={metricsData.stash.volumeReceived.label}
          description={metricsData.stash.volumeReceived.description}
          subDescription={metricsData.stash.volumeReceived.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={stashDataLoading}
          error={dataErrors.stash}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-orange500'
          label={metricsData.stash.volumeSent.label}
          description={metricsData.stash.volumeSent.description}
          subDescription={metricsData.stash.volumeSent.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={stashDataLoading}
          error={dataErrors.stash}
        />
        <TabsSectionItem
          className={cn('w-full min-w-0 flex-none', styles.sect)}
          tabs={metricsData.stash.paymentsMade.tabs}
          description={metricsData.stash.paymentsMade.description}
          loading={stashDataLoading}
          error={dataErrors.stash}
        />
      </Section>
      <Section title='Starter' subtitle='IBEX'>
        <SubDescriptionSectionItem
          labelClassName='!text-purple400'
          label={metricsData.starter.totalBalance.label}
          description={metricsData.starter.totalBalance.description}
          subDescription={metricsData.starter.totalBalance.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={starterDataLoading}
          error={dataErrors.starter}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-lime500'
          label={metricsData.starter.volumeReceived.label}
          description={metricsData.starter.volumeReceived.description}
          subDescription={metricsData.starter.volumeReceived.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={starterDataLoading}
          error={dataErrors.starter}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-orange500'
          label={metricsData.starter.volumeSent.label}
          description={metricsData.starter.volumeSent.description}
          subDescription={metricsData.starter.volumeSent.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={starterDataLoading}
          error={dataErrors.starter}
        />
        <TabsSectionItem
          className={cn('w-full min-w-0 flex-none')}
          tabs={metricsData.starter.paymentsMade.tabs}
          description={metricsData.starter.paymentsMade.description}
          loading={starterDataLoading}
          error={dataErrors.starter}
        />
      </Section>
    </Page>
  )
}
