import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { EditButton } from '@/components/Buttons/EditButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { Modal } from '@/components/Modal'
import ProtectedComponent from '@/components/ProtectedComponent'

import {
  IAccessCode,
  IHandleSentAccessCode
} from '@/types/access-code/access-code.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial: IAccessCode
}

const ToggleIsSent: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['toggle-is-sent', initial.id],
    mutationFn: async (data: IHandleSentAccessCode) => {
      await api.put(`admin/access-codes/${initial.id}/handle-sent`, {
        ...data
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success('Access code updated')
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while updating the access code'
      )
    }
  })

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IHandleSentAccessCode>({
    defaultValues: {
      is_sent: initial.is_sent,
      sent_to: initial.sent_to
    }
  })

  useEffect(() => {
    reset({
      is_sent: initial.is_sent,
      sent_to: initial.sent_to
    })
  }, [initial, reset])

  const onSubmit = (data: IHandleSentAccessCode) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug='access_codes_update'>
      <EditButton
        onClick={() => setIsOpen(true)}
        title='Edit'
        className='w-full'
      />
      <Modal visible={isOpen} onClose={() => setIsOpen(false)} overflow={false}>
        <Loader loading={isPending} type='absolute' />
        <Modal.Header
          title='Update Access Code'
          closable
          className='mb-[30px]'
        />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <Controller
              control={control}
              name='is_sent'
              render={({ field }) => (
                <div className='flex flex-col gap-2'>
                  <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                    <p>
                      {field.value
                        ? 'Access code has been sent'
                        : 'Access code has not been sent'}
                    </p>
                    <Switch
                      color='success'
                      isSelected={field.value}
                      onValueChange={() => field.onChange(!field.value)}
                    />
                  </label>
                  {field.value && (
                    <Field
                      placeholder='Sent To'
                      type='text'
                      {...register('sent_to', {
                        pattern: {
                          value:
                            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: 'Invalid email address'
                        }
                      })}
                      error={errors.sent_to?.message}
                    />
                  )}
                </div>
              )}
            />

            <FormButton
              type='submit'
              title={isPending ? 'Updating...' : 'Update'}
              disabled={isPending}
              stopPropagation
              preventDefault
              className='bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </ProtectedComponent>
  )
}

export default ToggleIsSent
